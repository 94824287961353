@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;500;600;700&display=swap');

html {
  scroll-behavior: smooth;
}

body {
  /* font-family: 'Oswald', sans-serif; */
  font-family: 'Fira Sans', sans-serif;
  letter-spacing: 0.5px;
  background-color: #f4f4f4;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background-color: #f4f4f4;
  border-radius: 100vh;
}

body::-webkit-scrollbar-thumb {
  background-image: linear-gradient(73deg, #364055, #111827);
  border-radius: 100vh;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 100vh;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-image: linear-gradient(73deg, #364055, #111827);
  border-radius: 100vh;
}